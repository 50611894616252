import React from 'react'
import styled from 'styled-components'

import ContentDefault from '../components/container'
import Layout from '../components/layout'
import banner from '../images/banner.png'

const Banner = styled.div`
  width: 100%;
  height: 160px;
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

const Content = styled(ContentDefault)`
  padding: 50px 0;
`

const Title = styled.h1`
  color: #102549;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
`

const Subtitle = styled.h2`
  font-size: 26px;
  font-weight: 600;
`

const Label = styled.p`
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  padding-left: 20px;
`

const Text = styled.p`
  padding-left: 20px;
`

const Mail = styled.a`
  display: inline-block;
  margin-bottom: 1rem;
  padding-left: 20px;
`

const Governanca = () => (
  <Layout title='Governança' page='governanca'>
    <Banner />
    <Content>
      <Title>Governança</Title>

      <Subtitle>Diretoria 2018/2020</Subtitle>

      <Label>Presidente</Label>
      <Text>Francisco Martins de Queiroz</Text>
      <Label>Vice Presidente</Label>
      <Text>Fabiano Ramalho Moreira</Text>
      <Label>Diretor Técnico Aviação</Label>
      <Text>Luiz Antônio Moreira Fidelis</Text>
      <Label>Diretor de Segurança de Voo</Label>
      <Text>Álvaro Augusto Ucho de Albuquerque</Text>
      <Label>Diretor de Instrução</Label>
      <Text>Ernani Epifani Lemos Junior</Text>
      <Label>Diretor de Materiais</Label>
      <Text>Thiago Samarony Sibalde Marques</Text>
      <Label>Diretor Administrativo Financeiro</Label>
      <Text>Thiago Medeiros da Cunha</Text>
      <Label>Diretor de Esportes e Eventos</Label>
      <Text>Júlio Cesar Moreira Pinheiro</Text>

      <Subtitle>Conselho Fiscal</Subtitle>

      <Label>Presidente</Label>
      <Text>Eduardo Almeida G. De Oliveira</Text>
      <Label>Conselheiro</Label>
      <Text>Eider Nogueira Mendes Neto</Text>
      <Label>Conselheiro</Label>
      <Text>Carlos Luiz Cavalcante de Lima</Text>
      <Label>Suplente</Label>
      <Text>Marcondes Rodrigues Pinheiro</Text>
      <Label>Suplente</Label>
      <Text>Ednaldo Soares da Silva</Text>
      <Label>Suplente</Label>
      <Text>José Maria de Queiroz</Text>

      <Subtitle>Gestão Administrativa</Subtitle>

      <Label>Gerente Administrativo Financeiro</Label>
      <Text className='mb-0'>Luiz Moura</Text>
      <Mail href='mailto:gerencia@aeroclubern.com.br'>gerencia@aeroclubern.com.br</Mail>

      <Label>Gerente Jurídico</Label>
      <Text className='mb-0'>Alexandre Alencar</Text>
      <Mail href='mailto:juridico@aeroclubern.com.br'>juridico@aeroclubern.com.br</Mail>

      <Label>Coordenador de Esportes</Label>
      <Text className='mb-0'>Matheus Moreira</Text>
      <Mail href='mailto:esporte@aeroclubern.com.br'>esporte@aeroclubern.com.br</Mail>
    </Content>
  </Layout>
)

export default Governanca
